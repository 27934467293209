<template>
  <div class="password">
    <div class="public-screen middle-box animated fadeIn">
      <img class="logo" src="@/assets/images/prologistics_logo.png" alt="Logo"/>
      <h2 class="m-t-xl m-b-md">{{ title }}</h2>

      <b-form @submit.prevent="onSubmit">
        <generic-input
            v-if="!isPasswordReset && current_user && !current_user.has_no_password"
            label="common.current_password"
            v-model="current_password"
            type="password"
            required
        ></generic-input>

        <generic-input
            label="common.new_password"
            v-model="password"
            type="password"
            required
        ></generic-input>

        <generic-input
            label="common.password_confirmation"
            v-model="password_confirmation"
            type="password"
            required
        ></generic-input>

        <b-button class="m-b block full-width" type="submit" variant="primary">
          {{ $t('button.save') }}
        </b-button>
      </b-form>

      <shared-links :showBackButton="true" class="text-center"/>
    </div>

  </div>
</template>

<script>
import SharedLinks from "@/components/shared_links";
import {updatePassword, updatePasswordByResetToken} from "@/services/employees";
import {mapGetters} from "vuex";
import store from "@/store";
import GenericInput from "@/components/generic_input.vue";

export default {
  components: {GenericInput, SharedLinks},
  data() {
    return {
      current_password: '',
      password: '',
      password_confirmation: ''
    }
  },
  methods: {
    onSubmit(event) {
      if (this.isPasswordReset) {
        updatePasswordByResetToken(this.$route.query.reset_password_token, this.password, this.password_confirmation).then(response => {
          this.$router.push({name: "dashboard"})
        })
      } else {
        updatePassword(this.current_password, this.password, this.password_confirmation).then(response => {
          if (this.current_user?.has_no_password) {
            const current_user = this.current_user
            current_user.has_no_password = false
            store.dispatch("user/setUser", current_user)
          }
          this.$router.push({name: "dashboard"})
        })
      }
    },
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    title() {
      if (this.isPasswordReset) return this.$t("common.enter_new_password")
      return this.$t("password.change_your_password")
    },
    isPasswordReset() {
      return !this.current_user && this.$route.query.reset_password_token
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  width: 15em;
  margin-top: 40px;
  margin-bottom: 40px;
}

label {
  color: #757575;
}

h2 {
  color: #222222;
  font-weight: bold;
}
</style>